<template>
  <div>
    <VerifyTyc />
    <div v-if="this.$store.state.tyc == 1"><verifyPromo /></div>
    <MenuVirtuales/>
    <div v-show="currentBono.toLocaleString() > 0" class="text-center pb-2">
      <v-dialog
        v-show="currentBono.toLocaleString() > 0"
        transition="dialog-bottom-transition"
        max-width="450"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            block
            @click="getPromo()"
            color="primary"
            v-bind="attrs"
            v-on="on"
            ><span class="white--text  ">Libera tu Bono</span></v-btn
          >
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark
              >Datos para la liberacion:</v-toolbar
            >
            <v-card-text>
              <div class="text-h7 pa-3">
                <span v-if="promo.venta == null" class="green--text "
                  >Jugada: 0 UND</span
                ><span v-if="promo.venta != null" class="green--text text-h6"
                  >Jugadas Cerradas:
                  {{ promo.venta.toLocaleString() }} UND</span
                ><br /><span v-if="promo.preventa != null" class="black--text"
                  >Jugada Abiertas:
                  {{ promo.preventa.toLocaleString() }} UND</span
                ><br /><span class="blue--text ">
                  Meta: {{ promo.meta }} Und</span
                ><br /><span class="gray--text text-h5  ">
                  Faltan:
                  {{
                    parseFloat(promo.meta) - parseFloat(promo.venta)
                  }}
                  Und</span
                ><br /><br /><span
                  v-if="parseFloat(promo.venta) >= parseFloat(promo.meta)"
                  class="green--text "
                >
                  Bono Liberado!!! <br />
                  en minutos estara en su cartera.</span
                ><span
                  v-if="parseFloat(promo.venta) < parseFloat(promo.meta)"
                  class="red--text "
                >
                  No Liberado</span
                >
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>



    <v-overlay :value="overlay" style="height: 100vh">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <iframe
      :src="gameUrl"
      :style="
        size < 960
          ? 'width: 100%; height: calc(100vh - 16px)'
          : 'width: 100%; height: calc(100vh - 95px)'
      "
      frameborder="0"
    ></iframe>
    <MisCuentas/>
    
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import MisCuentas from "../components/MisCuentas.vue";
import MenuVirtuales from "../components/MenuVirtuales.vue";
export default {
    data() {
        return {
            gameUrl: null,
            overlay: true,
            isMobile: 0,
            mon: null,
            currentBono: 0,
            promo: [],
        };
    },
    watch: {
        idmoneda() {
            this.overlay = true;
            this.getGame();
        },
    },
    beforeDestroy() {
        if (typeof window === "undefined")
            return;
        window.removeEventListener("resize", this.onResize, { passive: true });
    },
    mounted() {
        this.isMobile = window.innerWidth < 600 ? 1 : 0;
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
        this.currentBono = this.$store.state.currentBono;
        this.getPromo();
        axios({
            method: "post",
            url: `details`,
        }).then((response) => {
            this.$store.state.nombre = response.data.success.name;
        });
        this.getSaldo();
        if (document.getElementById("fc_frame")) {
            document.getElementById("fc_frame").style.display = "none";
        }
        this.idpos = sessionStorage.getItem("idpos");
        this.getGame();
    },
    updated() {
        if (document.getElementById("fc_frame")) {
            document.getElementById("fc_frame").style.display = "none";
        }
    },
    methods: {
        ...mapActions(["getSaldo"]),
        onResize() {
            this.isMobile = window.innerWidth < 600 ? 1 : 0;
        },
        getPromo() {
            axios({
                method: "get",
                url: `/getpromo`,
            }).then((response) => {
                console.log(response);
                this.promo = response.data.promo[0];
                //console.log(this.promo.venta);
            });
        },
        getGame() {
            this.mon = "";
            if (this.$store.state.idmoneda == 1)
                this.mon = "VES";
            else if (this.$store.state.idmoneda == 2)
                this.mon = "USD";
            axios({
                method: "post",
                url: `https://apicasiersweb.elinmejorable.bet/trackandraces/login`,
                data: {
                    email: this.$store.state.idpos + "@tr.com",
                    cedula: this.$store.state.cedula,
                    usuario: this.$store.state.idpos,
                    empresa: "Trackandraces",
                    SubAgenteNombre: this.$store.state.email,
                    alias: this.$store.state.nombre + "-" + this.$store.state.idpos,
                    moneda: this.mon,
                    api_key: "90ff35de-0ff9-11ec-82a8-0242ac130003"
                },
            }).then((response) => {
                console.log(response);
                if (this.$store.state.pais_user == "Venezuela" && this.$store.state.idmoneda < 3) {
                    this.gameUrl = response.data.data.url;
                    //alert("Las unidades de su Cartera seran convertidas en Bolivares para realizar las Jugadas");
                }
                else {
                    window.location.replace("#/bet");
                    this.gameUrl = "";
                    //alert("Disponible solo para Carteras en Bolivares");
                }
                this.overlay = false;
            }).catch(function (error) {
                this.gameUrl = "";
                return Promise.reject(error);
            });
        },
    },
    computed: {
        size() {
            return this.$store.state.windowSize.x;
        },
        moneda() {
            return this.$store.state.moneda;
        },
        idmoneda() {
            return this.$store.state.idmoneda;
        },
        idsb() {
            return this.$store.state.idsb;
        },
        currentBalance() {
            return this.$store.state.currentBalance;
        },
        idpos: {
            get() {
                return this.$store.state.idpos;
            },
            set(e) {
                this.$store.state.idpos = e;
            },
        },
    },
    components: { MisCuentas,MenuVirtuales }
};
</script>

<style></style>
