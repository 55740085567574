<template>
  <div>
    <VerifyTyc />
    <div v-if="this.$store.state.tyc == 1"><verifyPromo /></div>
    <MisCuentas />
    <v-overlay :value="overlay" style="height: 100vh">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <div>
        <template v-if="$vuetify.breakpoint.lgAndUp">
          <v-btn
            v-scroll="fScroll"
            style="z-index: 99"
            fixed
            large
            :text="!color"
            :color="color == false ? 'white' : 'primary'"
            v-if="!overlay"
            @click="$store.state.filter = true"
            depressed
          >
            <v-icon color="white" dark class="mr-3">mdi-filter-variant</v-icon>
            <span class="white--text">Filtro</span>
          </v-btn>
        </template>
      </div>
      <div style="height: 100vh" v-if="overlay"></div>
      <v-row class="pt-10">
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
          v-for="(item, key, idx) in newGames"
          :key="idx + 'a'"
        >
          <div v-if="item && item.html" :id="key">
            <v-toolbar class="rounded" dense flat :color="colors.perfil" height="40px">
              <v-toolbar-title class="white--text text-uppercase"
                >- {{ key }} -</v-toolbar-title
              >

              <v-spacer></v-spacer>
            </v-toolbar>
            <v-row>
              <v-col
                cols="6"
                sm="3"
                md="2"
                lg="2"
                xl="2"
                v-for="(ite, i) in item.html"
                :key="i + 'b'"
              >
                <div
                  class="cc"
                  @click="openGame(ite.name, ite.code)"
                  style="cursor: pointer"
                >
                  <div class="c-img">
                    <v-img
                      eager
                      style="border-radius: 12px"
                      :src="ite.image"
                      width="100px"
                    ></v-img>
                  </div>
                  <v-img
                    eager
                    class="img-b"
                    style="border-radius: 10px"
                    src="../assets/img/casino.svg"
                    width="145px"
                  ></v-img>
                  <h5 class="c-text text-center mt-2 white--text">{{ ite.name }}</h5>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="$store.state.filter" scrollable max-width="702px">
      <v-card>
        <v-card-title>Filtro</v-card-title>
        <v-card-text>
          <v-btn
            v-for="(item, idx) in gamesNames"
            class="mx-2 mb-3"
            :key="idx"
            depressed
            @click="goTo(item)"
            >{{ item }}</v-btn
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="$store.state.filter = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      @click="toTop"
      :style="$vuetify.breakpoint.mdAndDown ? 'bottom:60px' : ''"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import MisCuentas from "../components/MisCuentas.vue";
export default {
    data() {
        return {
            gamesNames: [],
            gameUrl: null,
            openIframe: false,
            overlay: false,
            newGames: [],
            filter: false,
            fab: false,
            color: false,
        };
    },
    mounted() {
        axios({
            method: "post",
            url: `details`,
        }).then((response) => {
            this.$store.state.nombre = response.data.success.name;
        });
        if (document.getElementById("fc_frame")) {
            document.getElementById("fc_frame").style.display = "none";
        }
        this.getGameList();
        this.getSaldo();
        console.log(this.saldos);
    },
    updated() {
        if (document.getElementById("fc_frame")) {
            document.getElementById("fc_frame").style.display = "none";
        }
    },
    methods: {
        ...mapActions(["getSaldo"]),
        openGame(name, code) {
            this.$router.push(`/game/${name.replace(" ", "-")}/${code}`);
        },
        fScroll(e) {
            if (typeof window === "undefined")
                return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.color = top > 5;
        },
        onScroll(e) {
            if (typeof window === "undefined")
                return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.fab = top > 20;
        },
        toTop() {
            this.$vuetify.goTo(0);
        },
        goTo(item) {
            this.$store.state.filter = false;
            this.$vuetify.goTo("#" + item, {
                duration: 500,
                easing: "easeInOutCubic",
            });
            // setTimeout(()=>{
            //   this.$vuetify.goTo('#'+item)
            // },1000)
        },
        getGameList() {
            this.overlay = true;
            axios
                .get("https://api.dotslotsuite.com/api/games?client_id=38&client_secret=9307ca38-ad1c-47fa-8c25-f70aad7e6f94&new=2")
                .then((e) => {
                this.filterGames(e.data.data.games);
                this.overlay = false;
            });
        },
        filterGames(array) {
            let games = {};
            let gamesNames = [];
            array.forEach((e) => {
                if (e.type == "html") {
                    if (games[e.maker] == undefined) {
                        games[e.maker] = {};
                        gamesNames.push(e.maker);
                    }
                    if (games[e.maker][e.type] == undefined) {
                        games[e.maker][e.type] = [];
                    }
                    games[e.maker][e.type].push(e);
                }
            });
            this.gamesNames = gamesNames;
            this.newGames = games;
        },
    },
    computed: {
        colors() {
            return this.$store.state.colors;
        },
    },
    components: { MisCuentas }
};
</script>

<style>
.cc {
  position: relative;
}

.img-b {
  margin: auto;

  display: flex;
  width: 100px;
}

.c-img {
  width: 100px;
  display: flex;
  margin: auto;
  z-index: 2;
  right: 0;
  left: 0;
  position: absolute;
  margin-top: 5px;
}
</style>
