<template>
  <v-row>
    <v-col
      class="px-2"
      :cols="12"
      md="6"
      lg="4"
      v-for="tr in data"
      :key="tr.id"
    >
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div>
              <v-img
                class
                contain
                :aspect-ratio="$store.state.windowSize.x < 940 ? 7 : 4"
                :src="tr.imagen"
              ></v-img>
              <b class="d-block text-center" v-if="tr.banco">{{ tr.banco }}</b>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <div v-if="tr.tipo == 'Plin'">
                <v-row>
					<v-col class="py-0 pl-0 d-flex" >
                    <b class="ml-auto">Escanea y Notifica:</b>
                  </v-col>
                  <v-col class="py-0 pl-0 d-flex">
                    <v-img
                      class="ml-auto"
                      max-height="250"
                      max-width="250"
                      src="https://trackandraces.com/dominio/tr/plintr.jpg"
                    ></v-img>
                  </v-col>
                  
                </v-row>
              </div>

              <div v-else>
                <v-row>
                  <v-col class="py-0 pl-0 d-flex" cols="4">
                    <b class="ml-auto">Nombre:</b>
                  </v-col>
                  <v-col class="py-0 px-0 d-flex">
                    <span>{{ tr.titular }}</span>
                  </v-col> </v-row
                ><br />

                <v-row v-if="tr.id_tipo_cuenta == 8">
                  <v-col class="py-0 pl-0 d-flex" cols="4">
                    <b class="ml-auto">DNI:</b>
                  </v-col>
                  <v-col class="py-0 px-0 d-flex">
                    <span>{{ tr.cedula }}</span>
                  </v-col>
                  <br />
                </v-row>

                <v-row v-if="tr.id_tipo_cuenta == 8">
                  <v-col class="py-0 pl-0 d-flex" cols="4">
                    <b class="ml-auto">Email:</b>
                  </v-col>
                  <v-col class="py-0 px-0 d-flex">
                    <span>info@trackandraces.com</span>
                  </v-col>
                  <br />
                </v-row>

                <v-row v-if="tr.id_tipo_cuenta == 8">
                  <v-col class="py-0 pl-0 d-flex" cols="4">
                    <b class="ml-auto">Banco:</b>
                  </v-col>
                  <v-col class="py-0 px-0 d-flex">
                    <span>{{ tr.banco }}</span>
                  </v-col>
                  <br />
                </v-row>

                <v-row>
                  <v-col class="py-0 pl-0 d-flex" cols="4">
                    <b class="ml-auto">Cuenta:</b>
                  </v-col>
                  <v-col class="py-0 px-0 d-flex">
                    <span>{{ tr.n_cuenta }}</span>
                  </v-col>
                  <br />
                </v-row>

                <v-row v-if="tr.id_tipo_cuenta == 8">
                  <v-col class="py-0 pl-0 d-flex" cols="4">
                    <b class="ml-auto">IBAN:</b>
                  </v-col>
                  <v-col class="py-0 px-0 d-flex">
                    <span>{{ tr.iban }}</span>
                  </v-col>
                  <br />
                </v-row>

                <v-row v-if="tr.id_tipo_cuenta == 8">
                  <v-col class="py-0 pl-0 d-flex" cols="4">
                    <b class="ml-auto">SWIFT:</b>
                  </v-col>
                  <v-col class="py-0 px-0 d-flex">
                    <span>{{ tr.swift }}</span>
                  </v-col>
                  <br />
                </v-row>

                <v-row v-if="tr.id_tipo_cuenta == 8">
                  <v-col class="py-0 pl-0 d-flex" cols="4">
                    <b class="ml-auto">Teléfono:</b>
                  </v-col>
                  <v-col class="py-0 px-0 d-flex">
                    <span>{{ tr.telefono }}</span>
                  </v-col>
                  <br />
                </v-row>

                <v-row>
                  <v-col class="pt-0 pl-0 d-flex" cols="4">
                    <b class="ml-auto">Pais:</b>
                  </v-col>
                  <v-col class="pt-0 px-0">
                    <span>{{ tr.paisnombre }}</span>
                  </v-col>
                  <br />
                </v-row>

                <!-- <v-text-field
											class="mt-2"
											v-model.lazy="montoRe"
											background-color="blue-grey lighten-5"
											hide-details
											label="Monto"
											single-line
											dense
											solo
											flat
											append-icon="mdi-currency-usd"
											type="number"
											min="1"
										></v-text-field>
										<v-text-field
											class="my-2"
											v-model="ref"
											background-color="blue-grey lighten-5"
											single-line
											dense
											solo
											flat
											hide-details
											label="Referencia"
											append-icon="mdi-card-text-outline"
										></v-text-field>
										<v-textarea
											v-model="commet"
											rows="2"
											background-color="blue-grey lighten-5"
											solo
											flat
											label="Comentario"
						></v-textarea>-->
              </div>
              <v-btn block color="#0eb3ef" text @click="notificar(tr.id)"
                >Notificar recarga</v-btn
              >
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["data"],
  methods: {
    notificar(v) {
      this.$emit("noty", v);
    },

    // accImage(value) {
    // 	let image;
    // 	if (value == 1 || value == "Zelle") {
    // 		image = require("../assets/img/zelle.png");
    // 	} else if (value == 2 || value == "PayPal") {
    // 		image = require("../assets/img/paypal.png");
    // 	} else if (value == 3 || value == "Skrill") {
    // 		image = require("../assets/img/skrill.png");
    // 	} else if (value == 4 || value == "Uphold") {
    // 		image = require("../assets/img/uphold.png");
    // 	} else if (value == 5 || value == "Payoneer") {
    // 		image = require("../assets/img/payoneer.png");
    // 	} else if (value == 6 || value == "TransferWise") {
    // 		image = require("../assets/img/transferwise.png");
    // 	} else if (value == 7 || value == "AirTM") {
    // 		image = require("../assets/img/airtm.png");
    // 	} else if (value == 8 || value == "Transferencia Bancaria") {
    // 		image = require("../assets/img/bank.png");
    // 	} else if (value == 9 || value == "Yape") {
    // 		image = require("../assets/img/yape.png");
    // 	}
    // 	return image;
    // }
  },
};
</script>

<style></style>
