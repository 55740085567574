<template>
  <div>
    <VerifyTyc />
    <div v-if="this.$store.state.tyc == 1"><verifyPromo /></div>
    <br />
    <v-row>
      <v-col
        cols="12"
        md="3"
        class="pt-1"
        v-if="$store.state.windowSize.x > 940"
      >
        <!-- Menu panel desktop  -->
        <v-card :color="colors.sidebar" dark>
          <v-card-title
            class="py-2 mb-1 elevation-1"
            :style="
              `background:${
                marca == 'Betgana Races' ? colors.panelbar : colors.primary
              };border-radius:4px`
            "
          >
            <v-select
              color="#fff"
              label="Mi cartera"
              single-line
              dense
              solo
              flat
              prepend-icon="mdi-wallet"
              background-color="transparent"
              hide-details
              v-model="idmoneda"
              required
              item-text="moneda"
              item-value="idmoneda"
              type="currency"
              :items="saldos"
            >
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.simbolo }} ({{ data.item.moneda }})
                {{
                  data.item.pais == "ECUADOR"
                    ? "- ECUADOR"
                    : data.item.pais == "EEUU"
                    ? "- EEUU"
                    : ""
                }}
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.simbolo }} ({{ data.item.moneda }})
                {{
                  data.item.pais == "ECUADOR"
                    ? "- ECUADOR"
                    : data.item.pais == "EEUU"
                    ? "- EEUU"
                    : ""
                }}
              </template>
            </v-select>
            <!-- <h5 class="font-weight-medium mx-auto text-uppercase">Panel de usuario</h5> -->
          </v-card-title>
          <v-list
            class="profile-sidebar"
            nav
            color="transparent"
            dense
            dark
            subheader
            style="

border-top-left-radius: 16px;

border-bottom-right-radius: 16px;

height: calc(100vh - 244px);

"
          >
            <div style="overflow: auto; height: calc(100%)" class="pt-1">
              <v-list-item link to="/profile">
                <v-list-item-icon>
                  <v-icon class>mdi-chart-donut</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <b style="font-weight: 500">DASHBOARD</b>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action></v-list-item-action>
              </v-list-item>

              <v-divider
                inset
                dark
                style="border-color: #ffffff1f !important"
              ></v-divider>

              <v-list-item link to="/rewards">
                <v-list-item-icon>
                  <v-icon class>mdi-ticket</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <b style="font-weight: 500">TICKET PREMIADOS</b>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action></v-list-item-action>
              </v-list-item>

              <v-divider
                inset
                dark
                style="border-color: #ffffff1f !important"
              ></v-divider>

              <v-list-item link to="/operations">
                <v-list-item-icon>
                  <v-icon class>mdi-cog-transfer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <b style="font-weight: 500">OPERACIONES</b>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action></v-list-item-action>
              </v-list-item>

              <v-divider
                inset
                dark
                style="border-color: #ffffff1f !important"
              ></v-divider>

              <v-list-item link to="/plays">
                <v-list-item-icon>
                  <v-icon class>mdi-horseshoe</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <b style="font-weight: 500">JUGADAS</b>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action></v-list-item-action>
              </v-list-item>
              <v-divider
                dark
                style="border-color: #ffffff1f !important"
              ></v-divider>

              <v-subheader inset class="ml-5 mt-2" style="height: 30px"
                >Administración</v-subheader
              >

              <v-list-item link to="/accounts">
                <v-list-item-icon>
                  <v-icon class>mdi-wallet</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <b style="font-weight: 500">MIS CARTERAS</b>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action></v-list-item-action>
              </v-list-item>

              <v-divider
                inset
                dark
                style="border-color: #ffffff1f !important"
              ></v-divider>

              <v-list-item link to="/referidos">
                <v-list-item-icon>
                  <v-icon class>mdi-star</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <b style="font-weight: 500">MIS REFERIDOS</b>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action></v-list-item-action>
              </v-list-item>

              <v-divider
                inset
                dark
                style="border-color: #ffffff1f !important"
              ></v-divider>

              <v-list-item link to="/withdrawals">
                <v-list-item-icon>
                  <v-icon class>mdi-bank-transfer-out</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <b style="font-weight: 500">HISTORIAL DE RETIRO</b>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action></v-list-item-action>
              </v-list-item>

              <v-divider
                inset
                dark
                style="border-color: #ffffff1f !important"
              ></v-divider>

              <v-list-item link to="/recharges">
                <v-list-item-icon>
                  <v-icon class>mdi-bank-transfer-in</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <b style="font-weight: 500">HISTORIAL DE RECARGAS</b>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action></v-list-item-action>
              </v-list-item>

              <v-divider
                dark
                style="border-color: #ffffff1f !important"
              ></v-divider>

              <v-subheader inset class="ml-5 mt-2" style="height: 30px"
                >Cuenta</v-subheader
              >

              <v-list-item link @click="$store.state.dialogChangePass = true">
                <v-list-item-icon>
                  <v-icon class>mdi-lock</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <b style="font-weight: 500">CAMBIAR CONTRASEÑA</b>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action></v-list-item-action>
              </v-list-item>

              <!-- <v-list-item link @click="$store.state.dialogResetPass = true">
                <v-list-item-icon>
                  <v-icon class>mdi-lock-reset</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <b style="font-weight:500">RESTABLECER CONTRASEÑA</b>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action></v-list-item-action>

</v-list-item>-->
            </div>
          </v-list>
          <div class="mt-2 px-2">
            <div class="pa-0 mb-1">
              <v-btn
                block
                depressed
                :color="colors.primary"
                dark
                @click="$store.state.dialogRe = true"
              >
                <b :class="marca == 'Betgana Races' ? 'black--text' : ''"
                  >RECARGAR SALDO</b
                >
              </v-btn>
            </div>

            <div class="pa-2 px-0">
              <v-btn
                block
                color="transparent"
                style="

background-image: linear-gradient(

60deg,

#a50005 0%,

#ff0008 100%

);

"
                dark
                depressed
                @click="dialogPay = true"
                >SOLICITAR PAGO</v-btn
              >
            </div>
          </div>
          <!-- Fin menu panel desktop  -->
        </v-card>
      </v-col>

      <v-col cols="12" md="9" class="pt-1">
        <router-view></router-view>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogChangePass" max-width="360">
      <form @submit="changePass">
        <v-card flat>
          <v-card-text>
            <v-card-title class="d-flex">
              <span class="mx-auto">CAMBIO DE CONTRASEÑA</span>
            </v-card-title>
            <v-text-field
              :color="colors.perfil"
              v-if="dialogChangePass"
              autofocus
              class="mt-4 mb-3"
              label="Contraseña actual"
              single-line
              dense
              solo
              flat
              background-color="blue-grey lighten-5"
              hide-details
              append-icon="mdi-lock"
              required
              v-model="newPass"
              type="password"
            ></v-text-field>
            <v-text-field
              :color="colors.perfil"
              background-color="blue-grey lighten-5"
              hide-details
              label="Nueva contraseña"
              single-line
              dense
              solo
              flat
              append-icon="mdi-lock"
              type="password"
              required
              v-model="newPassConf"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              :color="colors.perfil"
              background-color="blue-grey lighten-5"
              hide-details
              label="Confirmar nueva contraseña"
              single-line
              dense
              solo
              flat
              append-icon="mdi-lock"
              type="password"
              required
              v-model="cnewPassConf"
            ></v-text-field>
            <v-btn
              class="mb-2 mt-4"
              :color="colors.primary"
              :loading="loading"
              depressed
              :dark="marca != 'Betgana Races'"
              block
              type="submit"
              >CAMBIAR CONTRASEÑA</v-btn
            >
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn
                class
                color="#eb262c"
                :loading="loading"
                :disabled="loading"
                text
                block
                dark
                @click="dialogChangePass = false"
                >Cancelar</v-btn
              >
            </v-card-actions>
            <v-alert v-if="inv" type="error"
              >Las contraseñas no coinciden, por favor verifique.</v-alert
            >
          </v-card-text>
        </v-card>
      </form>
    </v-dialog>

    <v-dialog v-model="dialogPay" @input="hola" max-width="340" scrollable>
      <v-card flat>
        <v-card-text>
          <v-card-title class="pb-3 px-1">Solicitar pago</v-card-title>
          <div class="mb-2 ml-1">
            <b>
              SALDO ACTUAL:
              <span v-if="saldos && saldos[0]" style="color: #4caf50"
                >{{ saldos[index].balance.toLocaleString() }} UND</span
              >
            </b>
          </div>
          <v-select
            :color="colors.perfil"
            class="mb-3"
            label="Mi cartera"
            single-line
            dense
            solo
            flat
            background-color="blue-grey lighten-5"
            hide-details
            v-model="moneda"
            required
            item-text="moneda"
            item-value="idmoneda"
            type="currency"
            :items="saldos"
          >
            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              {{ data.item.simbolo }} ({{ data.item.moneda }})
              {{
                data.item.pais == "ECUADOR"
                  ? "- ECUADOR"
                  : data.item.pais == "EEUU"
                  ? "- EEUU"
                  : ""
              }}
            </template>
          </v-select>

          <v-select
            :color="colors.perfil"
            v-model="idAccount"
            solo
            dense
            flat
            background-color="blue-grey lighten-5"
            label="Tu cuenta"
            single-line
            hide-details
            :loading="loading"
            :items="accounts"
            item-value="id"
            item-text="tipo"
            class="mt-0 pt-0 mb-4"
            :menu-props="{ closeOnContentClick: true }"
          >
            <template v-slot:item="{ item }">
              <v-list-item @click="idAccount = item.id">
                <v-list-item-avatar size="40" tile>
                  <v-img
                    contain
                    width="80px"
                    :aspect-ratio="
                      item.tipo == 'Transferencia Bancaria' ? 2 : 4
                    "
                    :src="item.imagen"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ item.tipo }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.titular
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    item.n_cuenta
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ item.banco }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
          <v-text-field
            :color="colors.perfil"
            v-model="monto"
            @keyup="verificarMontoPago($event.target.value)"
            background-color="blue-grey lighten-5"
            hide-details
            label="Monto"
            single-line
            dense
            solo
            flat
            type="number"
            step=".01"
          >
            <template v-slot:append>
              <b>UND</b>
            </template>
          </v-text-field>
          <div class="my-3 ml-1">
            Monto a retirar:
            <b>
              {{
                (parseFloat(monto) * parseFloat($store.state.multiplo)).toFixed(
                  2
                )
              }}
              {{ $store.state.unidad }}
            </b>
          </div>
          <div v-if="mayor">
            <v-alert dense class="mb-0 mt-0" type="error" color="#eb262c"
              >El monto es mayor al saldo, por favor verifique</v-alert
            >
          </div>
          <v-btn
            :color="colors.primary"
            dark
            outlined
            class="ml-auto mb-2 mb-lg-0"
            :block="size < 960"
            @click="
              $store.state.dialogPay = false;
              $router.push('/accounts/0/' + $store.state.idmoneda);
              $store.state.dialogAccount = true;
            "
          >
            <v-icon>mdi-plus</v-icon>AÑADIR CUENTA
          </v-btn>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn text color="#eb262c" @click="dialogPay = false"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="#0eb3ef"
            :loading="loading"
            :disabled="loading || monto == '' || idAccount == null || mayor"
            @click="requestPayment()"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogNotificar" max-width="360">
      <form @submit="confirmRe">
        <v-card flat>
          <v-card-text>
            <v-card-title class="color body-1 px-0 py-1"
              >Notificar</v-card-title
            >
            <v-text-field
              :color="colors.perfil"
              class="mt-2"
              v-model.lazy="montoRe"
              background-color="blue-grey lighten-5"
              hide-details
              :label="`Monto en ${saldos[idx].simbolo} (${saldos[idx].moneda})`"
              single-line
              dense
              solo
              flat
              type="number"
              min="1"
              step=".01"
              required
            >
              <template v-slot:append>
                <b>{{ saldos[idx].simbolo }}</b>
              </template>
            </v-text-field>
            <v-text-field
              :color="colors.perfil"
              class="my-3"
              v-model="ref"
              background-color="blue-grey lighten-5"
              single-line
              dense
              solo
              flat
              hide-details
              label="Referencia"
              append-icon="mdi-card-text-outline"
              required
            ></v-text-field>
            <v-textarea
              :color="colors.perfil"
              v-model="commet"
              rows="2"
              background-color="blue-grey lighten-5"
              solo
              flat
              label="Comentario"
              hide-details
            ></v-textarea>
            <!-- {{ promo }}

<v-checkbox

v-if="promo && promo.nombre"

:color="colors.perfil"

dense

hide-details

v-model="idpromo"

:label="`Acepto la promoción`"

:value="promo.id"

></v-checkbox>-->
            <div
              style="display:none;"
              v-if="promo[0] && promo[0].nombre"
              class="mt-3"
            >
              <small>(Opcional)</small>
            </div>
            <v-select
              style="display:none;"
              clearable
              :color="colors.perfil"
              v-if="promo[0] && promo[0].nombre"
              label="Selecione promoción"
              single-line
              dense
              solo
              flat
              background-color="blue-grey lighten-5"
              hide-details
              v-model="idpromo"
              required
              item-text="nombre"
              item-value="id"
              :items="promo"
            ></v-select>
            <v-checkbox
              :color="colors.perfil"
              dense
              hide-details
              v-model="checkboxLicito"
              :label="
                `Declaro que los fondos utilizados en esta operación, tiene su origen y destino lícito.`
              "
              required
            ></v-checkbox>
            <v-checkbox
              :color="colors.perfil"
              dense
              hide-details
              v-model="checkboxImpot"
              :label="
                `Acepto al enviar este formulario, los terminos y condiciones sobre la operatividad de nuestro sitio web.`
              "
              required
            ></v-checkbox>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn text color="#eb262c" @click="dialogNotificar = false"
              >Cerrar</v-btn
            >
            <v-btn
              text
              color="#0eb3ef"
              :loading="loading"
              :disabled="loading"
              type="submi"
              >Aceptar</v-btn
            >
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>

    <v-dialog v-model="dialogRe" max-width="1024" scrollable>
      <v-card flat>
        <v-card-text class="pb-3">
          <v-card-title class="color body-1 px-0 d-flex">
            Recargar saldo
            <div
              class="ml-auto"
              :style="
                $store.state.windowSize.x < 940
                  ? 'width:100%;margin-top:12px'
                  : ''
              "
            >
              <v-select
                :color="colors.perfil"
                label="Mi cartera"
                single-line
                dense
                solo
                flat
                background-color="blue-grey lighten-5"
                hide-details
                v-model="CuentasIdMoneda"
                required
                item-text="moneda"
                item-value="idmoneda"
                type="currency"
                :items="saldos"
              >
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.simbolo }} ({{ data.item.moneda }})
                  {{
                    data.item.pais == "ECUADOR"
                      ? "- ECUADOR"
                      : data.item.pais == "EEUU"
                      ? "- EEUU"
                      : ""
                  }}
                </template>
              </v-select>
            </div>
          </v-card-title>
          <h1
            class="text-center my-12 font-weight-regular"
            v-if="!cuentasTr.length && tr"
          >
            No tiene agregado ningun metodo de pago
          </h1>
          <div v-if="!tr">
            <h1 class="text-center my-12 font-weight-regular">
              Selecione su cartera
            </h1>
          </div>
          <bank v-if="!loading" :data="cuentasTr" @noty="notificar"></bank>
          <div v-else class="my-6">
            <v-progress-circular
              class="d-flex mx-auto mt-11"
              :size="100"
              indeterminate
              color="black"
              :width="3"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn text color="#eb262c" @click="dialogRe = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConf" max-width="360">
      <v-card flat>
        <v-card-text class="pb-3">
          <v-card-title class="color body-1 px-0"
            >Confirmar recarga</v-card-title
          >
          <div>
            <span class="text-center"
              >Se le sumara a su saldo un total de:</span
            >
            <br />
            <b class="text-center">
              {{
                (parseFloat(montoRe) / parseFloat(saldos[idx].cambio)).toFixed(
                  2
                )
              }}
              UND
            </b>
          </div>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn text color="#eb262c" @click="dialogConf = false"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="#0eb3ef"
            :loading="loading"
            :disabled="loading"
            @click="requestRecharge"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="$store.state.snack" color="white">
      <v-list-item-avatar
        color="#eb262c"
        size="32"
        v-if="
          $store.state.msg ==
            'La carrera asociada a la apuesta se encuentra cerrada' ||
            $store.state.msg == 'Error al Verificar las Claves'
        "
      >
        <v-icon dark>mdi-cancel</v-icon>
      </v-list-item-avatar>
      <v-list-item-avatar color="green" size="32" v-else>
        <v-icon dark>mdi-check</v-icon>
      </v-list-item-avatar>

      <b style="color: #000">{{ $store.state.msg }}</b>
      <v-btn color="blue" text @click="$store.state.snack = false"
        >Cerrar</v-btn
      >
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import verifyPromo from "../components/VerifyPromo.vue";
import { mapActions } from "vuex";
import { mapState } from "vuex";

import bank from "../components/Bank.vue";

export default {
  components: {
    bank,
    verifyPromo,
  },
  data() {
    return {
      dialogNotificar: false,
      dialogConf: false,
      countries: [],
      balance: "",
      loading: false,
      id: null,
      snack: false,
      nombre: "",
      selectedAccountType: null,
      monto: 1,
      cuentasTr: [],
      montoRe: "",
      ref: "",
      commet: "",
      mayor: false,
      accounts: [],
      idAccount: null,
      newPass: "",
      newPassConf: "",
      cnewPassConf: "",
      inv: false,
      checkboxImpot: false,
      checkboxLicito: false,
      moneda: "",
      globalIdMoneda: 0,
      CuentasIdMoneda: 0,
      index: 0,
      idx: 0,
      tr: false,
      promo: [],
      idpromo: "",
    };
  },
  watch: {
    montoRe() {
      this.montoRe < 0
        ? (this.montoRe = 1)
        : this.montoRe == 0
        ? (this.montoRe = 1)
        : this.montoRe;
    },
    dialogConf(v) {
      if (!v) {
        (this.commet = ""), (this.ref = ""), (this.montoRe = "");
        (this.checkboxImpot = false), (this.checkboxLicito = false);
      }
    },
    dialogRe(e) {
      if (e) {
        e;
        // this.getPromo();
        // this.getTrAccounts();
      }
    },
    moneda(e) {
      this.index = this.saldos.findIndex((x) => x.idmoneda === e);
      this.getAccounts();
    },
    CuentasIdMoneda(e) {
      this.getPromo(e);
      this.idx = this.saldos.findIndex((x) => x.idmoneda === e);
      this.getCuentasTr();
    },
    dialogChangePass(v) {
      if (!v) {
        (this.newPass = ""), (this.newPassConf = ""), (this.cnewPassConf = "");
      }
    },
  },
  computed: {
    ...mapState({
      saldos: "saldos",
    }),
    idmoneda: {
      get() {
        return this.$store.state.idmoneda;
      },
      set(e) {
        this.$store.state.idmoneda = e;
      },
    },
    contryCurrency() {
      return this.$store.state.contryCurrency;
    },
    marca() {
      return this.$store.state.marca;
    },
    colors() {
      return this.$store.state.colors;
    },
    dialogRe: {
      get() {
        return this.$store.state.dialogRe;
      },
      set(v) {
        this.$store.state.dialogRe = v;
      },
    },
    dialogPay: {
      get() {
        return this.$store.state.dialogPay;
      },
      set(v) {
        this.$store.state.dialogPay = v;
      },
    },
    dialogAccount: {
      get() {
        return this.$store.state.dialogAccount;
      },
      set(v) {
        this.$store.state.dialogAccount = v;
      },
    },
    dialogChangePass: {
      get() {
        return this.$store.state.dialogChangePass;
      },
      set(v) {
        this.$store.state.dialogChangePass = v;
      },
    },
  },
  mounted() {
    this.getUser();
    this.getSaldo();
  },
  methods: {
    ...mapActions(["getSaldo", "changeIdMoneda"]),
    getPromo(e) {
      axios
        .get("/web_promo", {
          params: {
            modulo: 1,
            idmoneda: e,
          },
        })
        .then((e) => {
          console.log(e);
          this.promo = e.data.promo;
        });
    },
    confirmRe(e) {
      e.preventDefault();
      this.dialogConf = true;
      this.dialogNotificar = false;
    },
    hola(e) {
      if (!e) {
        this.monto = "";
        this.mayor = false;
      }
    },
    verificarMontoPago(e) {
      if (parseFloat(e) > parseFloat(this.saldos[this.index].balance)) {
        this.mayor = true;
      } else {
        this.mayor = false;
      }
    },
    getAccounts() {
      this.loading = true;
      axios({
        method: "get",
        url: `/new_miscuentas`,
        params: {
          idpais: this.moneda,
        },
      }).then((response) => {
        this.accounts = response.data.cuentas;
        this.loading = false;
      });
    },
    notificar(v) {
      this.$store.state.dialogRe = false;
      this.dialogNotificar = true;
      this.id = v;
    },
    changePass(e) {
      e.preventDefault();
      if (this.newPassConf == this.cnewPassConf) {
        this.inv = false;
        this.loading = true;
        axios({
          method: "post",
          url: `/cambiar_clave`,
          data: {
            password: this.newPassConf,
            password_old: this.newPass,
          },
        }).then((response) => {
          this.$store.state.dialogChangePass = false;
          this.$store.state.msg = response.data.message;
          this.$store.state.snack = true;
          this.loading = false;
        });
      } else {
        this.inv = true;
      }
    },
    getUser() {
      axios({
        method: "post",
        url: `/details`,
      }).then((response) => {
        console.log(response);
        this.$store.state.nombre = response.data.success.name;
        this.$store.state.idpromodia = response.data.promodia.promo;
        this.$store.state.nbpromodia = response.data.promodia.nbpromo;
      });
    },
    getCuentasTr() {
      this.loading = true;
      axios({
        method: "get",
        url: `/new_cuentas_tr`,
        params: {
          idpais: this.CuentasIdMoneda,
        },
      }).then((response) => {
        this.cuentasTr = response.data.cuentas;
        this.tr = true;
        this.loading = false;
      });
    },
    getTrAccounts() {
      axios({
        method: "get",
        url: `/cuentas_tr`,
      }).then((response) => {
        this.cuentasTr = response.data.cuentas;
      });
    },
    requestPayment() {
      if (
        parseFloat(this.monto) >
        parseFloat(this.$store.state.saldos[this.index].balance)
      ) {
        this.mayor = true;
      } else {
        this.loading = true;
        axios({
          method: "post",
          url: `/solicitud_retiro`,
          data: {
            id_cuenta: this.idAccount,
            monto: this.monto,
          },
        }).then((response) => {
          this.$store.state.msg = response.data.mensaje;
          this.$store.state.snack = true;
          this.dialogPay = false;
          this.loading = false;
          this.mayor = false;
        });
      }
    },
    requestRecharge(e) {
      e.preventDefault();
      this.loading = true;
      axios({
        method: "post",
        url: `/solicitud_ingreso`,
        data: {
          idpromo: this.idpromo,
          id_cuenta: this.id,
          ref_deposito: this.ref,
          monto:
            parseFloat(this.montoRe) / parseFloat(this.saldos[this.idx].cambio),
          observaciones: this.commet,
        },
      }).then((response) => {
        this.$store.state.msg = response.data.mensaje;
        this.$store.state.snack = true;
        this.loading = false;
        this.dialogConf = false;
        this.checkboxImpot = false;
        this.checkboxLicito = false;
      });
    },
  },
};
</script>

<style>
.animate {
  animation-duration: 0.6s;
  animation-name: leaves;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes leaves {
  0% {
    transform: scale(0.94);
  }

  100% {
    transform: scale(1.04);
  }
}

.op-title {
  height: 52px;
  background-image: linear-gradient(60deg, #005a9c 0%, #00a9e700 100%);
  border-radius: 4px !important;
  color: #fff;
}

.profile {
  background-image: linear-gradient(0deg, #1156b0 0%, #00afef 100%);
  border-radius: 4px;
  border-top-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.profile-item-ticket {
  background-image: linear-gradient(0deg, #1156b0 0%, #00afef 100%);
  border-radius: 4px;
  border-top-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.profile-item {
  background-image: linear-gradient(60deg, #1156b067 0%, #00afef00 80%);
  /* background-image: linear-gradient(0deg, #1257b1 0%, #2975da 100%); */
  color: #fff;
}

.blance-item {
  background-image: linear-gradient(0deg, #a50005 0%, #ff0008 100%);
  border-radius: 4px !important;
  /* border: 4px solid rgb(234, 255, 241); */
  color: #fff;
}

.static {
  background: white;
  border-radius: 4px !important;
  border-top-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.saldo {
  background-image: linear-gradient(60deg, #008006 0%, #66bb6a 100%);
}

.premios {
  background-image: linear-gradient(60deg, #eeb600 0%, #ffd900 100%);
}

.jugada {
  background-image: linear-gradient(60deg, #1156b0 0%, #00afef 100%);
}

.v-data-table__mobile-row__cell {
  width: 100%;
}

.profile-sidebar .v-list-item__title {
  font-size: 0.74rem !important;
}
</style>
