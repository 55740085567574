<template>
  <div>

    <div v-if="this.$store.state.tyc == 0">
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          persistent
          max-width="330px"
          no-click-animation
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              {{ dialogm1 }}
            </v-btn>
          </template>
          <template>
  <v-card
    height="310"
    width="256"
    class="mx-auto"
  >
    <v-navigation-drawer permanent>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Terminos y Condiciones
          </v-list-item-title>
          <v-list-item-subtitle>
            Nueva Actualizacion
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item @click="router-link" :to="{ path: '/tycg/tyc'}"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>TyC Generales</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="$router.push('/depositos')"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Depositos y Retiros</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="$router.push('/terminos')"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Hipismo y Deportes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push('/terminospoker')"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Poker Online</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <div class="text-center pt-2">
        <v-btn x-small color="#22344f" text @click="logout()" class="pr-10">

                Salir
              </v-btn>
              <v-btn dark color="#22344f" @click="guardarSeleccion()">
                Acepto
              </v-btn>

      </div>
      
    </v-navigation-drawer>
    
    
  </v-card>
</template>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dialogm1: 0,
      dialog: true,
      dialogPreg: false,
    };
  },
  mounted() {
    this.getTyc();
  },
  methods: {
    guardarSeleccion() {
      
        axios({
          method: "post",
          url: `/guardartyc`,
          data: {
            registro: 1,
          },
        }).then((response) => {
          this.$store.state.tyc = 1;
          this.$store.state.msg = response.data.mensaje;
          this.$store.state.idpromodia = 1;
          this.$store.state.nbpromodia = this.dialogm1;
          this.dialogPreg = false;
          this.dialog = false;
        });
      },
      getTyc() {
      this.loading = true;
      axios({
        method: "get",
        url: `/usertyc`,
        params: {
          idpais: this.moneda,
        },
      }).then((response) => {
        this.$store.state.tyc = response.data.registro
        this.accounts = response.data.cuentas;
        this.loading = false;
      });
    },
      logout() {
      axios({
        method: "post",
        url: `https://apitr.xyz/onlineapi/api/logout`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          accept: "application/json",
        },
      }).then((response) => {
        if (response.data.res) {
          this.$store.state.token = null;
          this.$router.replace("/");
          localStorage.removeItem("token");
          sessionStorage.removeItem("token");
        }
      });
    }
    },
  
};
</script>

<style lang="scss" scoped></style>
